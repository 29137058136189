module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Qbatch","short_name":"Qbatch","start_url":"/","display":"standalone","icon":"./src/assets/images/fav-icon.png","icons":[{"src":"./src/assets/images/fav-icon16.png","sizes":"8x8","type":"image/png"},{"src":"./src/assets/images/favicon16x16.png","sizes":"16x16","type":"image/png"},{"src":"./src/assets/images/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"./src/assets/images/favicon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"./src/assets/images/favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"./src/assets/images/favicon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"./src/assets/images/favicon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"./src/assets/images/favicon-70x70.png","sizes":"70x70","type":"image/png"},{"src":"./src/assets/images/favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./src/assets/images/favicon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"./src/assets/images/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"./src/assets/images/favicon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"./src/assets/images/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./src/assets/images/favicon-150x150.png","sizes":"150x150","type":"image/png"},{"src":"./src/assets/images/favicon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"./src/assets/images/favicon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"./src/assets/images/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./src/assets/images/favicon-310x310.png","sizes":"310x310","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"78246f31e1cd7640566a351c3778bcc9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
